<div
  (ghostClick)="ghostClick.emit()"
  (lockClick)="lockClick.emit()"
  [ghostConfig]="ghostConfig"
  [labels]="labels"
  [lockConfig]="lockConfig"
  [locked]="locked"
  [ngClass]="{ 'push-to-bottom': pushToBottom }"
  [state]="state"
  [userLabels]="userLabels"
  appGhostOverlay
  class="overflow-hidden fut-card-secondary {{ type }}">
  @if (cardTitleString || cardTitleTemplate) {
    <div class="d-flex justify-content-between align-items-center">
      @if (cardTitleString) {
        <span
          [ngClass]="{
            'fut-skeleton': state === 'LOADING' || state === 'GHOST',
            ghost: state === 'GHOST',
            'fut-h3': cardTitleType === 'title',
            'fut-bold': cardTitleType === 'title',
          }"
          >{{ cardTitleString | transloco }}</span
        >
        @if (icon) {
          @if (!infoTemplate) {
            <mat-icon class="pointer" [fontSet]="fontSet" (click)="iconClick.next()">{{ icon }}</mat-icon>
          }
          @if (infoTemplate) {
            <fut-info-panel [infoTitle]="infoTitle || cardTitleString" [iconInfo]="{ icon, fontSet: fontSet }" [infoTemplate]="infoTemplate"></fut-info-panel>
          }
        }
      }
      @if (cardTitleTemplate) {
        <ng-container *ngTemplateOutlet="cardTitleTemplate; context: { state: state }"></ng-container>
      }
    </div>
  }
  <ng-content></ng-content>
</div>
