import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { StreakInfo } from '../model/streak.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { FutDialogService } from 'src/app/core/dialog-service/fut-dialog.service';
import { StreakDialogComponent } from '../components/streak-dialog/streak-dialog.component';

export const ON_STREAK_PHRASES = [
  'streak.on_streak.phrases.1',
  'streak.on_streak.phrases.2',
  'streak.on_streak.phrases.3',
  'streak.on_streak.phrases.4',
  'streak.on_streak.phrases.5',
  'streak.on_streak.phrases.6',
  'streak.on_streak.phrases.7',
  'streak.on_streak.phrases.8',
  'streak.on_streak.phrases.9',
  'streak.on_streak.phrases.10',
] as const;

@Injectable({ providedIn: 'root' })
export class StreakService {
  public triggerReloadSubject = new Subject<void>();

  constructor(
    private http: HttpClient,
    private dialog: FutDialogService
  ) {}

  public get(): Observable<StreakInfo> {
    return this.http.get<StreakInfo>(environment.be_url + '/data/streak');
  }

  public loadStreakInfo(streakInfo: StreakInfo): { bestStreakCount: number; currentStreakCount: number } {
    let bestStreakCount = 0;
    let currentStreakCount = 0;

    // Nessuna streak
    if (streakInfo.streaks.length == 0) return { bestStreakCount, currentStreakCount };
    if (!streakInfo.max_streak) return { bestStreakCount, currentStreakCount };

    // if(!streakInfo.current_streak) return {bestStreakCount, currentStreakCount};

    // Nessuna migliore
    if (streakInfo.streaks.length == 1) {
      currentStreakCount = streakInfo.max_streak.count;
      return { bestStreakCount, currentStreakCount };
    }

    // Sei nella tua streak migliore
    if (streakInfo.max_streak.end === streakInfo.current_streak?.end) {
      currentStreakCount = streakInfo.current_streak.count;

      // Si ottiene la vecchia streak migliore
      bestStreakCount = Math.max(...streakInfo.streaks.filter(streak => streak.end !== streakInfo.current_streak!.end).map(streak => streak.count));
      return { bestStreakCount, currentStreakCount };
    }

    bestStreakCount = streakInfo.max_streak.count;
    currentStreakCount = streakInfo.current_streak?.count || 0;

    return { bestStreakCount, currentStreakCount };
  }

  public openDialog(streakInfo?: StreakInfo) {
    return this.dialog.open<StreakDialogComponent, StreakInfo | undefined>(StreakDialogComponent, {
      matConf: { data: streakInfo },
      dimension: 'md',
    });
  }

  public triggerReload(): void {
    return this.triggerReloadSubject.next();
  }
}
