import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/config/config.service';
import { UserService } from '../../../core/user-service/user.service';
import { RouterLink, sidenavElements } from '../model/sidenav.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CacheBuilder } from '@futura/futura-ui/cache';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private readonly hiddenPageCache = CacheBuilder.simple<string, Array<RouterLink>>()
    .setExpireTime(60 * 60 * 1_000)
    .build();

  constructor(
    private config: ConfigService,
    private userService: UserService
  ) {}

  public hiddenPages(): Observable<Array<RouterLink>> {
    return this.hiddenPageCache.get('hiddenPages', () => {
      const elements = sidenavElements;
      return forkJoin([
        this.userService.getFuturaUser(),
        this.config.getUiConfig('_hidden_sidenav_elements'),
        this.config.getUiConfig('_section_license'),
      ]).pipe(
        map(([user, hiddenElements, sections]) => {
          const toHide = hiddenElements || [];

          sections?.forEach(section => {
            const element = elements.find(element => element.routerLink == section.section);
            if (!element) {
              return;
            }

            const res = this.userService.checkLables(this.userService.getUserLabelsInstant(user), section.lables, section.whitelist);
            if (!res) {
              toHide?.push(element.routerLink);
            }
          });
          return toHide;
        })
      );
    });
  }
}
