import { AnimationOptions } from 'ngx-lottie';

interface BaseSidenavElement<T extends string> {
  name: string;
  routerLink: T;
  lottie: AnimationOptions;
  lottieActive: AnimationOptions;
}

function sidenavBuilder<T extends string>(sidenavElements: Array<BaseSidenavElement<T>>): Array<BaseSidenavElement<T>> {
  return sidenavElements;
}

export const sidenavElements = sidenavBuilder([
  {
    name: 'simulations',
    routerLink: 'simulations',
    lottie: { path: '/assets/lotties/sidenav/simulations.json' },
    lottieActive: { path: '/assets/lotties/sidenav/simulations-active.json' },
  },
  {
    name: 'folder',
    routerLink: 'folder',
    lottie: { path: '/assets/lotties/sidenav/folder.json' },
    lottieActive: { path: '/assets/lotties/sidenav/folder-active.json' },
  },
  {
    name: 'simulations-light',
    routerLink: 'simulations-light',
    lottie: { path: '/assets/lotties/sidenav/simulations-light.json' },
    lottieActive: { path: '/assets/lotties/sidenav/simulations-light-active.json' },
  },
  {
    name: 'metrics',
    routerLink: 'metrics',
    lottie: { path: '/assets/lotties/sidenav/metrics.json' },
    lottieActive: { path: '/assets/lotties/sidenav/metrics-active.json' },
  },
  {
    name: 'statistics',
    routerLink: 'statistics',
    lottie: { path: '/assets/lotties/sidenav/statistics.json' },
    lottieActive: { path: '/assets/lotties/sidenav/statistics-active.json' },
  },
  {
    name: 'community',
    routerLink: 'community',
    lottie: { path: '/assets/lotties/sidenav/community.json' },
    lottieActive: { path: '/assets/lotties/sidenav/community-active.json' },
  },
  {
    name: 'live',
    routerLink: 'live',
    lottie: { path: '/assets/lotties/sidenav/live.json' },
    lottieActive: { path: '/assets/lotties/sidenav/live-active.json' },
  },
  {
    name: 'rec',
    routerLink: 'rec',
    lottie: { path: '/assets/lotties/sidenav/rec.json' },
    lottieActive: { path: '/assets/lotties/sidenav/rec-active.json' },
  },
  {
    name: 'archive',
    routerLink: 'archive',
    lottie: { path: '/assets/lotties/sidenav/archive.json' },
    lottieActive: { path: '/assets/lotties/sidenav/archive-active.json' },
  },
  {
    name: 'planner',
    routerLink: 'planner',
    lottie: { path: '/assets/lotties/sidenav/planner.json' },
    lottieActive: { path: '/assets/lotties/sidenav/planner-active.json' },
  },
  {
    name: 'products',
    routerLink: 'products',
    lottie: { path: '/assets/lotties/sidenav/products.json' },
    lottieActive: { path: '/assets/lotties/sidenav/products-active.json' },
  },
  {
    name: 'profile',
    routerLink: 'profile',
    lottie: { path: '' },
    lottieActive: { path: '' },
  },
]);

export type RouterLink = (typeof sidenavElements)[number]['routerLink'];

export type SidenavElement = BaseSidenavElement<RouterLink>;
